import React from 'react';
import Tag from '@nib/tag';
import {Inline} from '@nib/layout';
import {formControlTypeValues, validformControlTypeValues} from '../common-prop-types';

export interface LabelWithTagProps {
  label: string;
  formControlType?: formControlTypeValues;
}

const LabelWithTag: React.FC<LabelWithTagProps> = props => {
  const {label, formControlType} = props;
  return (
    <Inline space={3}>
      <span>{label}</span>

      {formControlType && validformControlTypeValues.includes(formControlType) && (
        <Tag size="small" variation={formControlType === 'optional' ? 'info' : 'warning'}>
          {formControlType.charAt(0).toUpperCase() + formControlType.slice(1)}
        </Tag>
      )}
    </Inline>
  );
};

LabelWithTag.displayName = 'LabelWithTag';

export default LabelWithTag;
