import React from 'react';
import styled from 'styled-components';
import {Columns, Column, mr, py, breakpoint} from '@nib/layout';
import {colorLighter, copyColor, copyFontSizeSmall} from '@nib-components/theme';

import InclusionIcon from '../InclusionIcon';
import {COVER_TYPES_DESCRIPTIONS, COVER_TYPES_Values} from '../constants';

export interface StaticInclusionType {
  id: number;
  name: string;
  description?: string;
  coveredTypeCode: COVER_TYPES_Values;
  waitingPeriod?: string;
}
export interface InclusionType extends StaticInclusionType {
  description: string;
  waitingPeriod: string;
  maxWaitingPeriod?: {
    name: string;
    value: number;
  };
  disclaimer?: string;
}

export interface Inclusion extends InclusionType {
  orderScore: number;
  annualLimits?: AnnualLimit[];
  pcatProductId: number;
  policyBookletLink?: string;
  displayOrder: number;
}

export interface ServiceComponent extends InclusionType {
  id: number;
  name: string;
  description: string;
  orderScore: number;
  displayOrder: number;
  coveredTypeCode: COVER_TYPES_Values;
  productServices: Inclusion[];
  inclusionScore?: number;
}

export interface AnnualLimit {
  id: number;
  applyToName: string;
  limitAmount: number;
  limitText: string;
  productId: number;
  productName: string;
  serviceId: number;
  timePeriod: string;
  annualIncrease?: number;
  maxLimitPerTimePeriod?: number;
  childLimits?: ChildLimit[];
}

export interface ChildLimit {
  id: number;
  productId: number;
  productName: string;
  parentId: number;
  serviceId: number;
  serviceName: string;
  limitAmount: number;
  limitText: string;
  timePeriod: string;
  applyToName: string;
}
export interface InclusionProps {
  inclusion: StaticInclusionType;
  hideBottomBorder?: boolean;
  [key: string]: unknown;
}

export const InclusionLi = styled.li<InclusionProps>`
  display: block;
  ${py(2)};
  border-bottom: 1px solid var(--themeColorBorder, ${colorLighter});
  color: var(--themeColorFg, ${copyColor});
  text-align: start;
  line-height: 1.5;
  list-style: none;
  font-size: ${copyFontSizeSmall};
`;

const FixedWidth32px = styled.div`
  width: 32px;
  ${mr(1)};
  text-align: center;
`;

export const InclusionName = styled.span`
  line-height: 1.5;
  word-break: break-word;
  hyphens: auto;

  ${breakpoint('mini')`
    hyphens: none;  
  `}
`;

const Inclusion: React.FC<InclusionProps> = ({inclusion}) => {
  return (
    <InclusionLi>
      <Columns collapseBelow="mini">
        <Column width="content">
          <FixedWidth32px>
            <InclusionIcon coveredTypeCode={inclusion.coveredTypeCode} />
          </FixedWidth32px>
        </Column>
        <Column>
          <InclusionName aria-label={`${inclusion.name}, ${COVER_TYPES_DESCRIPTIONS[inclusion.coveredTypeCode]}`}>{inclusion.name}</InclusionName>
        </Column>
      </Columns>
    </InclusionLi>
  );
};

Inclusion.displayName = 'Inclusion';

export default Inclusion;
