import React from 'react';
import styled from 'styled-components';
import Inclusion, {InclusionType, ServiceComponent} from '../Inclusion';
import InclusionDetailModal from '../InclusionDetailModal';
import {LimitsForActiveServiceForEachProductProps} from '../AnnualLimitsTable';
import InclusionButton from '../InclusionButton';

export const InclusionListUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export interface ProductType {
  claimsPercentage: number;
  description: string;
  id: number;
  name: string;
  productServices: [];
  type: string;
}

export interface InclusionListProps {
  inclusions: InclusionType[];
  products?: ProductType[];
  productId?: number;
  limitsForActiveServiceForEachProduct?: LimitsForActiveServiceForEachProductProps[];
  onInclusionModalOpen?: (id?: number, products?: ProductType[]) => void;
  onInclusionModalClose?: () => void;
  policyBookletLink?: string;
  extrasProductName?: string;
  serviceComponent?: ServiceComponent;
  isStatic?: boolean;
  [key: string]: unknown; // other props
}

interface InclusionListState {
  isModalVisible: boolean;
  inclusion: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
}

class InclusionList extends React.Component<InclusionListProps, InclusionListState> {
  constructor(props: InclusionListProps) {
    super(props);
    this.state = {
      isModalVisible: false,
      inclusion: {
        name: '',
        description: ''
      }
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleInclusionClick = this.handleInclusionClick.bind(this);
  }

  handleClose() {
    this.setState({
      isModalVisible: false
    });

    if (this.props.onInclusionModalClose) {
      this.props.onInclusionModalClose();
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  handleInclusionClick(inclusion: any) {
    const {onInclusionModalOpen, products} = this.props;

    this.setState({
      isModalVisible: true,
      inclusion
    });

    if (onInclusionModalOpen) {
      onInclusionModalOpen(inclusion.id, products);
    }
  }

  render() {
    const {isStatic, inclusions, productId, serviceComponent, extrasProductName, limitsForActiveServiceForEachProduct, policyBookletLink = '/docs/policy-booklet'} = this.props;

    if (isStatic) {
      return (
        <InclusionListUl>
          {inclusions.map((inclusion, index) => (
            <Inclusion key={`li-${inclusion.id}-${index}`} inclusion={inclusion} index={index} />
          ))}
        </InclusionListUl>
      );
    }

    return (
      <React.Fragment>
        <InclusionListUl>
          {inclusions.map((inclusion, index) => (
            <InclusionButton key={`li-${inclusion.id}-${index}`} inclusion={inclusion} index={index} onInclusionClick={this.handleInclusionClick} />
          ))}
        </InclusionListUl>

        <InclusionDetailModal
          title={this.state.inclusion.name}
          visible={this.state.isModalVisible}
          onClose={this.handleClose}
          description={this.state.inclusion.description}
          waitingPeriod={this.state.inclusion.waitingPeriod}
          coveredTypeCode={this.state.inclusion.coveredTypeCode}
          policyBookletLink={policyBookletLink}
          disclaimer={this.state.inclusion.disclaimer}
          limitsForActiveServiceForEachProduct={limitsForActiveServiceForEachProduct}
          productId={productId}
          inclusionId={this.state.inclusion.id}
          extrasProductName={extrasProductName}
          serviceComponent={serviceComponent}
        />
      </React.Fragment>
    );
  }

  static displayName = 'InclusionList';
}

export default InclusionList;
