import React from 'react';
import {SecondaryButton} from '@nib-components/button';
import Copy, {Bold} from '@nib-components/copy';
import Heading, {componentValues} from '@nib-components/heading';
import Divider from '@nib-components/divider';
import Modal from '@nib-components/modal';
import {Box, Inline, Margin} from '@nib/layout';
import Tag from '@nib/tag';

import Markdown from '../Markdown';
import CoverTypeDescription, {CoverTypeExplanation} from '../CoverTypeDescription';
import AnnualLimitsTable, {LimitsForActiveServiceForEachProductProps} from '../AnnualLimitsTable';
import InclusionIcon from '../InclusionIcon';
import {COVER_TYPES_DESCRIPTIONS, COVER_TYPES_TAG_TYPES, COVER_TYPES_Values} from '../constants';
import {ServiceComponent, Inclusion} from '../Inclusion';
import AnnualLimitsText from '../AnnualLimitsText';

export interface InclusionDetailModalProps {
  title?: string;
  visible?: boolean;
  onClose?: () => void;
  inclusionId?: number;
  productId?: number;
  description?: string;
  waitingPeriod?: string;
  coveredTypeCode: COVER_TYPES_Values;
  policyBookletLink?: string;
  disclaimer?: string;
  limitsForActiveServiceForEachProduct?: LimitsForActiveServiceForEachProductProps[];
  customBodyComponent?: React.FC<Record<string, unknown>>;
  // If set, the modal will render content under the new 1..n ServiceComponent -> ProductBenefits model
  serviceComponent?: ServiceComponent;
  titleComponent?: componentValues;
  extrasProductName?: string;
}

const InclusionDetailModal: React.FC<InclusionDetailModalProps> = props => {
  const {
    title,
    visible,
    onClose,
    inclusionId,
    productId,
    description,
    waitingPeriod,
    coveredTypeCode,
    policyBookletLink,
    disclaimer,
    limitsForActiveServiceForEachProduct,
    serviceComponent,
    extrasProductName,
    titleComponent
  } = props;

  const isInclusion = coveredTypeCode == 'Inclusion';

  let activeProductHasInclusion;

  if (serviceComponent || !limitsForActiveServiceForEachProduct) {
    activeProductHasInclusion = !!inclusionId;
  } else {
    activeProductHasInclusion =
      inclusionId &&
      limitsForActiveServiceForEachProduct &&
      limitsForActiveServiceForEachProduct.length &&
      Boolean(limitsForActiveServiceForEachProduct.find(product => product.productId === productId));
  }

  return (
    <Modal title={title} visible={visible} onClose={onClose}>
      {coveredTypeCode && (
        <Tag variation={COVER_TYPES_TAG_TYPES[coveredTypeCode]}>
          <Inline space={2} verticalAlign="center">
            <InclusionIcon coveredTypeCode={coveredTypeCode} color="currentColor" />
            <span>{COVER_TYPES_DESCRIPTIONS[coveredTypeCode]}</span>
          </Inline>
        </Tag>
      )}

      {serviceComponent && extrasProductName ? (
        <>
          <Margin top={4} bottom={6}>
            <Heading component={titleComponent} size={4}>
              {isInclusion ? 'Included in' : 'Excluded from '} {extrasProductName}
            </Heading>
          </Margin>

          <Margin bottom={6}>
            <Markdown content={description} />
          </Margin>

          {coveredTypeCode == 'Inclusion' &&
            serviceComponent.productServices &&
            serviceComponent.productServices.length &&
            serviceComponent.productServices.map(productService => {
              const props = {...productService, extrasProductName};
              return (
                <Margin top={4} key={productService.id}>
                  <ProductBenefitContent {...props} />
                </Margin>
              );
            })}
        </>
      ) : (
        <>
          <Margin bottom={6}>
            <Markdown content={description} />
          </Margin>

          {activeProductHasInclusion && waitingPeriod && (
            <Margin bottom={6}>
              <Margin bottom={4}>
                <Heading component={titleComponent} size={4}>
                  Waiting period:
                </Heading>
              </Margin>
              <Copy>{waitingPeriod}</Copy>
            </Margin>
          )}

          {activeProductHasInclusion && limitsForActiveServiceForEachProduct && <AnnualLimitsTable limitsForActiveServiceForEachProduct={limitsForActiveServiceForEachProduct} productId={productId} />}

          {activeProductHasInclusion && disclaimer ? (
            <CoverTypeExplanation title="Disclaimer">{disclaimer}</CoverTypeExplanation>
          ) : (
            <CoverTypeDescription coveredTypeCode={coveredTypeCode} policyBookletLink={policyBookletLink} />
          )}
        </>
      )}

      <Margin top={7}>
        <Box textAlign="left">
          <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        </Box>
      </Margin>
    </Modal>
  );
};

const ProductBenefitContent = (productService: Inclusion & {extrasProductName: string; titleComponent?: componentValues}) => {
  const {name, waitingPeriod, description, disclaimer, pcatProductId, annualLimits, coveredTypeCode, policyBookletLink, extrasProductName, titleComponent} = productService;
  return (
    <>
      <Divider />
      <Margin bottom={6} top={7}>
        <Copy>Included within the</Copy>
        <Bold>{name} benefit</Bold>
      </Margin>

      <Margin bottom={7}>
        <Margin bottom={4}>
          <Heading component={titleComponent} size={5}>
            Benefit description:
          </Heading>
        </Margin>
        <Markdown content={description} />
      </Margin>

      {waitingPeriod && (
        <Margin bottom={7}>
          <Margin bottom={4}>
            <Heading component={titleComponent} size={5}>
              Waiting period:
            </Heading>
          </Margin>
          <Copy>{waitingPeriod}</Copy>
        </Margin>
      )}

      {annualLimits && annualLimits.length && (
        <AnnualLimitsText limitsForActiveServiceForEachProduct={annualLimits} productId={pcatProductId} onlyShowOwnProductLimits={true} extrasProductName={extrasProductName} />
      )}

      {disclaimer ? <CoverTypeExplanation title="Disclaimer">{disclaimer}</CoverTypeExplanation> : <CoverTypeDescription coveredTypeCode={coveredTypeCode} policyBookletLink={policyBookletLink} />}
    </>
  );
};

export default InclusionDetailModal;
